import { localizationManager } from './singletons'
import { FirebaseUtils } from './utils/firebaseUtils';
import { NavigationUtils } from './utils/navigationUtils';
import { UiUtils } from './utils/uiUtils'

const signinButton = UiUtils.getElementByClassName('signin');
const signupButton = UiUtils.getElementByClassName('register');
const forgotPasswordButton = UiUtils.getElementByClassName('forgot-password');

const setupUI = function () {
    localizationManager._22(signinButton, 'COMMON.SIGNIN');
    localizationManager._22(signupButton, 'COMMON.REGISTER');
    localizationManager._22(forgotPasswordButton, 'COMMON.FORGOT_PASSWORD');
}

window.addEventListener('pageshow', () => {
    FirebaseUtils.setupFirebase(user => {
        if (user !== null) {
            NavigationUtils.goToDashboard();
        } else {
            setupUI();
        }
    });
});
